import config from "@/config/config";
import { ReqConfig } from "./BaseBllInterface";
import qs from "qs";
import Tool from "@/app/tools/Tool";

abstract class BaseBll {
  /**
   * 构造函数
   */
  constructor() {}

  /**
   * url处理
   *
   * @param url
   */
  reqUrl(url: string) {
    const requestUrl = `${config.hostUrl}${url}`;
    return requestUrl;
  }

  reqConfig(config: ReqConfig, url: string) {
    const reqConfig = {
      url: this.reqUrl(url),
      data: qs.stringify(config.data),
      method: this.checkMethond(config.method),
      chain: config.chain,
      success: config.success,
      error: config.error,
    };
    return reqConfig;
  }

  isLoginHeaders() {
    return {
      "Content-Type": "application/x-www-form-urlencoded charset=UTF-8",
      Authorization: Tool.getJwt(),
    };
  }

  uploadHeaders() {
    return {
      "Content-Type": "Multipart/form-data",
      Authorization: Tool.getJwt(),
    };
  }

  /**
   * 请求前对发送的数据进行处理
   *
   * @param data
   */
  reqSetData(data: any) {
    return data;
  }

  /**
   * 检查请求方法
   *
   * @param methond
   * @returns
   */
  protected checkMethond(methond: string | undefined) {
    if (methond == undefined) {
      methond = "post";
    }
    return methond;
  }
}

export default BaseBll;
