import BaseController from "@/fast/api/BaseController";
import CommonBll from "@/app/api/bll/CommonBll";
import { DezipReqCa, DezipReqCb, UnzipReqCa, UnzipReqCb } from "./ZipInterface";
import { axiosReqConfig } from "@/fast/api/BaseControllerInterface";

export default class ZipController extends BaseController {
  /**
   * 逻辑处理层
   */
  private bll: CommonBll;

  constructor() {
    super();
    this.bll = new CommonBll();
  }

  async dezip(config: DezipReqCa | DezipReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(config, "/zip/dezip");
    reqConfig.timeout = 3600000;
    reqConfig.headers = this.bll.isLoginHeaders();
    return this.request(reqConfig);
  }

  async unzip(config: UnzipReqCa | UnzipReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(config, "/zip/unzip");
    reqConfig.timeout = 3600000;
    reqConfig.headers = this.bll.isLoginHeaders();
    return this.request(reqConfig);
  }
}
