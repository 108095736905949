import mitt from "mitt";

export interface UploadData {
  path: string;
}
export interface AddNewData {
  path: string;
}
export interface UnZipData {
  type: "file" | "dir";
  path: string;
  name: string;
}
export interface DeZipData {
  path: string;
  name: string;
}
export interface RenameData {
  type: "file" | "dir";
  path: string;
  name: string;
  model: "move" | "rename";
}
export interface CopyData {
  type: "file" | "dir";
  path: string;
  name: string;
}
export interface CommSelectData {
  path: string;
  model: "file" | "dir" | "all";
}
export interface CommSelectChoose {
  type: "file" | "dir";
  href: string;
}

type Mitt = {
  commSelect: CommSelectData;
  commSelectChoose: CommSelectChoose;
  ioSuccess: void;
  upload: UploadData;
  addNew: AddNewData;
  unZip: UnZipData;
  deZip: DeZipData;
  rename: RenameData;
  copy: CopyData;
};
const EventBus = mitt<Mitt>();
export default EventBus;
