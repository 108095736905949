import BaseViews from "@/fast/base/BaseView";
import EventBus, { UnZipData } from "@/fast/plugins/mitt/EventBus";
import { defineComponent } from "vue";
import ZipController from "@/app/api/controller/ZipController";
import { ElLoading, ElMessage } from "element-plus";
import CommonSelect from "@/app/components/common/select/Select.vue";

class Component extends BaseViews {
  constructor() {
    super();
  }

  public vue() {
    const vue = defineComponent({
      setup() {
        const zipC = new ZipController();
        return {
          zipC,
        };
      },
      data() {
        let type: any = "";
        return {
          show: false,
          type,
          path: "",
          name: "",

          CSHref: "",
          CSType: "",
          CSMode: "dir",
        };
      },
      created() {
        EventBus.on("unZip", this.unZip);
      },
      onBeforeUnmount() {
        EventBus.off("unZip", this.unZip);
      },
      methods: {
        unZip(data: UnZipData) {
          this.path = data.path;
          this.name = data.name;
          this.type = data.type;
          this.CSHref = data.path;
          this.show = true;
        },

        async ok() {
          const loadingInstance = ElLoading.service({
            lock: true,
            text: "正在压缩文件",
          });
          let res: any = await this.zipC.unzip({
            data: {
              path: this.path,
              name: this.name,
              type: this.type,
              output: this.CSHref,
            },
            chain: true,
            method: "post",
          });
          loadingInstance.close();
          if (res.result == true) {
            ElMessage.success(res.msg);
            EventBus.emit("ioSuccess");
            this.show = false;
          } else {
            ElMessage.error(res.msg);
          }
        },
      },
      components: {
        CommonSelect,
      },
    });
    return vue;
  }
}

export default Component;
