import Rsa from "@/app/tools/Rsa";
import Tool from "./Tool";

export interface ShowData {
  name: string;
  href: string;
  type: "file" | "dir";
}

export default class Io {
  static dwDir(Dir: any, path: string) {
    let goBackPath = "/";
    let dir: ShowData[] = [];
    if (path != "/") {
      let varPath = String(path);
      let pathArr = varPath.split("/");
      for (let index = 1; index < pathArr.length - 1; index++) {
        goBackPath += pathArr[index];
        if (index != pathArr.length - 2) {
          goBackPath += "/";
        }
      }
      dir = [
        {
          name: "..",
          type: "dir",
          href: `${goBackPath}`,
        },
      ];
    }
    for (let index = 0; index < Dir.length; index++) {
      let temp = Dir[index];
      let t_path = path == "/" ? "" : path;
      dir = dir.concat([
        {
          name: temp,
          type: "dir",
          href: `${t_path}/${temp}`,
        },
      ]);
    }
    return dir;
  }

  static dwFile(File: string[], path: string) {
    let file: ShowData[] = [];
    let hostUrl = Tool.getHostUrl();
    for (let index = 0; index < File.length; index++) {
      const element = File[index];
      let data = {
        path: path,
        name: element,
      };
      let dataStr = JSON.stringify(data);
      let token = btoa(Rsa.encode(dataStr));

      file = file.concat([
        {
          name: element,
          type: "file",
          href: `${hostUrl}/download?&token=${token}`,
        },
      ]);
    }
    return file;
  }
}
