import BaseViews from "@/fast/base/BaseView";
import EventBus, { DeZipData } from "@/fast/plugins/mitt/EventBus";
import { defineComponent } from "vue";
import ZipController from "@/app/api/controller/ZipController";
import { ElLoading, ElMessage } from "element-plus";
import CommonSelect from "@/app/components/common/select/Select.vue";

class Component extends BaseViews {
  constructor() {
    super();
  }

  public vue() {
    const vue = defineComponent({
      setup() {
        const zipC = new ZipController();
        return {
          zipC,
        };
      },
      data() {
        return {
          show: false,
          filename: "",

          CSHref: "",
          CSType: "",
          CSMode: "dir",
        };
      },
      created() {
        EventBus.on("deZip", this.deZip);
      },
      onBeforeUnmount() {
        EventBus.off("deZip", this.deZip);
      },
      methods: {
        deZip(data: DeZipData) {
          this.CSHref = data.path;
          this.CSType = "dir";
          this.filename = `${data.path == "/" ? "" : data.path}/${data.name}`;
          this.show = true;
        },

        async ok() {
          if (this.CSType == "dir") {
            if (this.CSHref != "") {
              const loadingInstance = ElLoading.service({
                lock: true,
                text: "正在解压",
              });
              let res: any = await this.zipC.dezip({
                data: {
                  filename: this.filename,
                  output: this.CSHref,
                },
                chain: true,
                method: "post",
              });
              loadingInstance.close();
              if (res.result == true) {
                ElMessage.success(res.msg);
                EventBus.emit("ioSuccess");
                this.show = false;
              } else {
                ElMessage.error(res.msg);
              }
            }
          }
        },
      },
      components: {
        CommonSelect,
      },
    });
    return vue;
  }
}

export default Component;
