import { RouteRecordRaw } from "vue-router";

import View from "@/app/views/view/View.vue";
import Show from "@/app/views/show/Show.vue";

const root: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/vue",
  },
  {
    path: "/vue/",
    component: View,
    children: [
      {
        path: "",
        redirect: "/vue/show",
      },
      {
        path: "show/:path",
        component: Show,
      },
      {
        path: "show",
        component: Show,
      },
      {
        path: "login",
        component: () => import("@/app/views/login/Login.vue"),
      },
    ],
  },
];

export default root;
