import BaseController from "@/fast/api/BaseController";
import CommonBll from "@/app/api/bll/CommonBll";
import {
  ShowReqCa,
  ShowReqCb,
  RenameReqCa,
  RenameReqCb,
  CopyReqCa,
  CopyReqCb,
  AddNewReqCa,
  AddNewReqCb,
} from "@/app/api/controller/IoInterface";
import { axiosReqConfig } from "@/fast/api/BaseControllerInterface";

class IoController extends BaseController {
  /**
   * 逻辑处理层
   */
  bll: CommonBll;

  constructor() {
    super();
    this.bll = new CommonBll();
  }

  /**
   * 显示
   *
   * @param config
   */
  async show(config: ShowReqCa | ShowReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(config, "/io/show");
    reqConfig.headers = this.bll.isLoginHeaders();
    reqConfig.timeout = 3600000;
    return this.request(reqConfig);
  }

  /**
   * 重命名
   *
   * @param config
   */
  async rename(config: RenameReqCa | RenameReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(config, "/io/rename");
    reqConfig.headers = this.bll.isLoginHeaders();
    reqConfig.timeout = 3600000;
    return this.request(reqConfig);
  }

  /**
   * 复制
   *
   * @param config
   */
  async copy(config: CopyReqCa | CopyReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(config, "/io/copy");
    reqConfig.headers = this.bll.isLoginHeaders();
    reqConfig.timeout = 3600000;
    return this.request(reqConfig);
  }

  /**
   * 复制
   *
   * @param config
   */
  async addnew(config: AddNewReqCa | AddNewReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(config, "/io/addnew");
    reqConfig.headers = this.bll.isLoginHeaders();
    return this.request(reqConfig);
  }
}

export default IoController;
