import BaseController from "@/fast/api/BaseController";
import CommonBll from "@/app/api/bll/CommonBll";
import {
  RemoveFileReqCa,
  RemoveFileReqCb,
  RemoveDirReqCa,
  RemoveDirReqCb,
} from "@/app/api/controller/RemoveIntreface";
import { axiosReqConfig } from "@/fast/api/BaseControllerInterface";

class RemoveController extends BaseController {
  /**
   * 逻辑处理层
   */
  bll: CommonBll;

  constructor() {
    super();
    this.bll = new CommonBll();
  }

  file(config: RemoveFileReqCa | RemoveFileReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(config, "/remove/file");
    reqConfig.headers = this.bll.isLoginHeaders();
    reqConfig.timeout = 3600000;
    return this.request(reqConfig);
  }

  dir(config: RemoveDirReqCa | RemoveDirReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(config, "/remove/dir");
    reqConfig.headers = this.bll.isLoginHeaders();
    reqConfig.timeout = 3600000;
    return this.request(reqConfig);
  }
}

export default RemoveController;
