const JSEncrypt = require("./resource/jsencrypt.js");

class RsaOpenSSL {
  static pubKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApEADq2kMWCmw3Tc4n02M
81DOtlTqkOtPKRIyp6i1yqONUmvnCWUIdf+HN+Zln4eEuFGVZsaRTisu58+vsAZ2
zlbaiW/tU6PcL0r06THYCDcGF5f7s8lGrSTEb2DWRSZb5hLqnRA4PnSP60yhp3lX
8bNCvA9TdVquiG2PPMwvaUhg4NXmA0MG10dxMVyhX6PAXKZVvqftogBd0ZKlrBeM
oBgoPOULZTYPVoHKuvY+TBM2TCx2iDek2+QaANyGy0paqmLuIcr3U6VIcKgaHyHq
UUyHw9y3W8aD3WYodBtN9f+QCcW/6+swEi3zG7L+Np79+ao7IsO2fiAJ8ccIJffK
XQIDAQAB
-----END PUBLIC KEY-----`;

  static priKey = ``;

  static encode(str: string) {
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(RsaOpenSSL.pubKey);
    let encrypted = encrypt.encrypt(str);
    return encrypted;
  }

  static decode(str: string) {
    let decrypt = new JSEncrypt();
    decrypt.setPrivateKey(RsaOpenSSL.priKey);
    let uncrypted = decrypt.decrypt(str);
    return uncrypted;
  }
}

export default RsaOpenSSL;
