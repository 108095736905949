import SparkMD5 from "spark-md5";
import UploadController from "@/app/api/controller/UploadController";
import EventBus from "@/fast/plugins/mitt/EventBus";
import { ElMessage } from "element-plus";
import Tool from "@/app/tools/Tool";
type ServerConfig = {
  path: string;
};

type NData = {
  onFinish: () => void;
  onError: () => void;
  onProgress: (e: { percent: number }) => void;
};

class UploadFile {
  file: File;
  serverConfig: ServerConfig;
  uuid = "";
  blobSlice = File.prototype.slice;
  chunkSize = 2097152;
  chunks = 0;
  currentChunk = 0;
  start = 0;
  end = 0;
  spark = new SparkMD5.ArrayBuffer();
  fileReader = new FileReader();
  uploadController = new UploadController();
  nData: NData;

  constructor(file: File, serverConfig: ServerConfig, nData: NData) {
    [this.file, this.serverConfig, this.nData, this.chunks] = [
      file,
      serverConfig,
      nData,
      Math.ceil(file.size / this.chunkSize),
    ];
  }

  async Start() {
    if (await this.getUuid()) {
      this.fileReader.onload = async (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          //这里是二进制数据
          if (e.target.result instanceof ArrayBuffer) {
            this.spark.append(e.target.result);
            this.currentChunk++;
            let percentage = Math.ceil((this.currentChunk / this.chunks) * 100);
            this.nData.onProgress({
              percent: percentage,
            });

            if (this.currentChunk < this.chunks) {
              this.loadNext();
            } else {
              let res: any = await this.done();
              if (!res.result) {
                this.nData.onError();
              }
              this.nData.onFinish();
              EventBus.emit("ioSuccess");
            }
          }
          //base64数据
          else if (typeof e.target.result == "string") {
            let res: any;
            if (Tool.getWebServe() == "tp") {
              res = await this.base64CallBack(e.target.result);
            } else {
              let form = new FormData();
              form.append("uuid", this.uuid);
              form.append("index", String(this.currentChunk));
              form.append(
                "slice",
                this.file.slice(this.currentChunk * this.chunkSize, this.end)
              );
              res = await this.upload(form);
            }
            if (!res.result) {
              this.nData.onError();
              ElMessage.error(res.msg);
            } else {
              this.fileReader.readAsArrayBuffer(
                this.blobSlice.call(this.file, this.start, this.end)
              );
            }
          } else {
            this.nData.onError();
          }
        } else {
          this.nData.onError();
        }
      };
      this.fileReader.onerror = () => {
        this.nData.onError();
      };
      this.loadNext();
    } else {
      this.nData.onError();
    }
  }

  async getUuid() {
    const res: any = await this.uploadController.getToken({
      data: { path: this.serverConfig.path },
      method: "post",
      chain: true,
    });
    if (res.result) {
      this.uuid = res.data.uuid;
      return true;
    } else {
      ElMessage.error(res.msg);
      return false;
    }
  }

  async done() {
    const data = {
      md5: this.spark.end(),
      uuid: this.uuid,
      name: this.file.name,
      path: this.serverConfig.path,
    };
    return await this.uploadController.done({
      data,
      method: "post",
      chain: true,
    });
  }

  /**
   * 使用二进制传输
   *
   * @param data
   * @returns
   */
  async upload(data: any) {
    return await this.uploadController.upload({
      data,
      method: "post",
      chain: true,
    });
  }

  /**
   *  base64回调
   *
   * @param slice  切片数据格式为base64
   */
  async base64CallBack(slice: string) {
    return await this.uploadController.upload({
      data: {
        uuid: this.uuid,
        index: this.currentChunk,
        slice: slice,
      },
      method: "post",
      chain: true,
    });
  }
  /**
   * 加载下一切片
   */
  loadNext() {
    this.start = this.currentChunk * this.chunkSize;
    this.end =
      this.start + this.chunkSize >= this.file.size
        ? this.file.size
        : this.start + this.chunkSize;
    this.fileReader.readAsDataURL(
      this.blobSlice.call(this.file, this.start, this.end)
    );
  }
}

class Upload {
  constructor() {}

  static uploadfile(file: File, serverConfig: ServerConfig, nData: NData) {
    return new UploadFile(file, serverConfig, nData).Start();
  }
}
export default Upload;
