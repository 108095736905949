import BaseViews from "@/fast/base/BaseView";
import EventBus, { AddNewData } from "@/fast/plugins/mitt/EventBus";
import { ElLoading, ElMessage } from "element-plus";
import { defineComponent } from "vue";
import IoController from "@/app/api/controller/IoController";

class Component extends BaseViews {
  constructor() {
    super();
  }

  public vue() {
    const vue = defineComponent({
      setup() {
        const ioC = new IoController();
        return {
          ioC,
        };
      },
      data() {
        return {
          show: false,
          path: "",
          type: "",
          name: "",
          option: [
            {
              name: "文件",
              value: "file",
            },
            {
              name: "文件夹",
              value: "dir",
            },
          ],
        };
      },
      created() {
        EventBus.on("addNew", this.addNew);
      },
      onUnmounted() {
        EventBus.off("addNew", this.addNew);
      },
      methods: {
        addNew(data: AddNewData) {
          this.path = data.path;
          this.type = "";
          this.name = "";
          this.show = true;
        },
        async ok() {
          if (this.type == "") {
            ElMessage.error("请选择类型");
            return;
          }
          if (this.type != "file" && this.type != "dir") {
            ElMessage.error("类型有误");
            return;
          }
          if (this.name == "") {
            ElMessage.error("请输入名称");
            return;
          }
          const loadingInstance = ElLoading.service({
            lock: true,
            text: "正在创建",
          });
          let res: any = await this.ioC.addnew({
            chain: true,
            method: "post",
            data: {
              type: this.type,
              from: `${this.path == "/" ? "" : this.path}/${(this, this.name)}`,
            },
          });
          loadingInstance.close();
          if (res.result == true) {
            ElMessage.success(res.msg);
            EventBus.emit("ioSuccess");
            this.show = false;
          } else {
            ElMessage.error(res.msg);
          }
        },
      },
      components: {},
    });
    return vue;
  }
}

export default Component;
