import Token from "@/common/Token";
import config from "@/config/config";
import LocalStorage from "@/common/LocalStorage";
const token = new Token("localStorage");

export default class Tool {
  static checkLogin() {
    return Tool.getJwt() != "";
  }

  static getJwt() {
    return token.getToken(config.tokenName);
  }

  static setJwt(cvalue: string, time: number) {
    token.setToken(config.tokenName, cvalue, time);
  }

  static getHostUrl() {
    return config.hostUrl;
  }

  static getWebServe() {
    let webServe = LocalStorage.getLocalStorage("webServe");
    if (webServe == "") {
      webServe = config.webServe;
      LocalStorage.setLocalStorage("webServe", webServe);
    }
    return webServe;
  }
}
