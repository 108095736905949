import BaseViews from "@/fast/base/BaseView";
import { defineComponent, h } from "vue";
import { useRoute, useRouter } from "vue-router";
import { FolderOpened, Document, More } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import { Base64 } from "js-base64";

import IoController from "@/app/api/controller/IoController";
import Upload from "@/app/components/upload/Upload.vue";
import Unzip from "@/app/components/zip/un/Unzip.vue";
import Dezip from "@/app/components/zip/de/Dezip.vue";
import Rename from "@/app/components/rename/Rename.vue";
import Copy from "@/app/components/copy/Copy.vue";
import AddNew from "@/app/components/addNew/AddNew.vue";

import EventBus from "@/fast/plugins/mitt/EventBus";
import Tool from "@/app/tools/Tool";
import RemoveController from "@/app/api/controller/RemoveController";
import Io from "@/app/tools/Io";

type ShowData = {
  name: string;
  href: string;
  type: "file" | "dir";
};

class Component extends BaseViews {
  constructor() {
    super();
  }

  public vue() {
    const vue = defineComponent({
      name: "Show",
      setup() {
        const route = useRoute();
        const router = useRouter();
        const jwt = Tool.getJwt();
        const hostUrl = Tool.getHostUrl();
        let path =
          route.params.path == undefined
            ? "/"
            : route.params.path == ""
            ? "/"
            : Base64.decode(String(route.params.path));
        const nologin = async () => {
          await router.push(`/vue/login?redirect=/vue/show?path=${path}`);
        };
        if (!Tool.checkLogin()) {
          nologin();
        }

        let pathInfo = [];
        (() => {
          let pathArr = path.split("/");
          if (pathArr[1] === "") {
            pathArr.pop();
          }
          let pathArrLenth = pathArr.length;
          let historyHref = "";
          for (let index = 0; index < pathArrLenth; index++) {
            const element = pathArr[index];
            let href = "";
            let name;
            let last = false;
            let separator = "";
            switch (true) {
              case index == 0:
                name = "/";
                historyHref += "";
                href = `/vue/show/${Base64.encode("/")}`;

                if (index == pathArrLenth - 1) {
                  last = true;
                }
                break;
              case index == pathArrLenth - 1:
                name = element;
                historyHref += `/${element}`;
                last = true;
                separator = "/";
                break;
              default:
                historyHref += "/" + element;
                href = `/vue/show/${Base64.encode(historyHref)}`;
                name = element;
                separator = "/";
                break;
            }

            pathInfo.push({
              href,
              name,
              last,
              separator,
            });
          }
        })();
        return {
          route,
          router,
          path,
          jwt,
          hostUrl,
          nologin,
          pathInfo,
        };
      },
      data() {
        let showData: ShowData[] = [];
        const ioReq = new IoController();
        const removeReq = new RemoveController();
        return {
          ioReq,
          removeReq,
          showData,
        };
      },
      created() {
        this.getData();
        EventBus.on("ioSuccess", this.getData);
      },
      onUnmounted() {
        EventBus.off("ioSuccess", this.getData);
      },
      methods: {
        /**
         * 获取显数据
         */
        async getData() {
          let path = String(this.path);
          const loadingInstance = ElLoading.service({
            lock: true,
            text: "正在获取数据",
          });
          let data: any = await this.ioReq.show({
            data: {
              path,
            },
            chain: true,
            method: "post",
          });
          loadingInstance.close();
          if (data.result == true) {
            let dirData: string[] = data.data.dir;
            let fileData: string[] = data.data.file;
            this.showData = Io.dwDir(dirData.sort(), path).concat(
              Io.dwFile(fileData.sort(), path)
            );
          } else {
            ElMessage.error(data.msg);
            if (data.needRouter == true) {
              this.router.push(
                `/vue/login?redirect=/vue/show?path=${this.path}`
              );
            }
          }
        },
        /**
         * 上传
         */
        upload() {
          EventBus.emit("upload", {
            path: String(this.path),
          });
        },
        /**
         * 新建
         */
        addNew() {
          EventBus.emit("addNew", {
            path: String(this.path),
          });
        },
        /**
         * 移除
         * @param val
         */
        remove(val: ShowData) {
          let msg = h("p", null, [
            h("span", null, val.type == "file" ? `删除文件` : `删除文件夹`),
            h("i", { style: "color: teal" }, ` ${val.name} `),
            h("span", null, `需要验证操作密码`),
          ]);

          ElMessageBox.prompt(msg, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            inputValidator: (value: string) => {
              if (value != null) {
                return value.length >= 6 ? true : "密码长度必需大于6";
              }
              return "密码长度必需大于6";
            },
            inputType: "password",
          })
            .then(async ({ value }) => {
              let result: any;
              const loadingInstance = ElLoading.service({
                lock: true,
                text: "正在删除",
              });
              if (val.type == "file") {
                result = await this.removeReq.file({
                  data: {
                    password: value,
                    path: `${String(this.path)}`,
                    name: val.name,
                  },
                  chain: true,
                  method: "post",
                });
              } else {
                result = await this.removeReq.dir({
                  data: {
                    password: value,
                    path: `${String(this.path)}/${val.name}`,
                  },
                  chain: true,
                  method: "post",
                });
              }
              loadingInstance.close();
              if (result.result == true) {
                ElMessage.success(result.msg);
                this.getData();
              } else {
                ElMessage.error(result.msg);
              }
            })
            .catch(() => {});
        },
        /**
         * 压缩zip
         *
         * @param val
         */
        unZip(val: ShowData) {
          EventBus.emit("unZip", {
            path: String(this.path),
            type: val.type,
            name: val.name,
          });
        },
        /**
         * 解压zip
         *
         * @param val
         */
        deZip(val: ShowData) {
          EventBus.emit("deZip", {
            path: String(this.path),
            name: val.name,
          });
        },
        /**
         * 重命名
         *
         * @param val
         * @param model
         */
        rename(val: ShowData, model: "move" | "rename") {
          EventBus.emit("rename", {
            path: String(this.path),
            type: val.type,
            name: val.name,
            model,
          });
        },
        /**
         * 复制
         *
         * @param val
         */
        copy(val: ShowData) {
          EventBus.emit("copy", {
            type: val.type,
            path: String(this.path),
            name: val.name,
          });
        },
        /**
         * 转跳
         *
         * @param val
         */
        async goto(val: ShowData) {
          switch (val.type) {
            case "dir":
              let path = Base64.encode(val.href);
              let url = `/vue/show/${path}`;
              window.location.href = url;
              break;
            case "file":
              window.open(val.href, "__blank");
              break;
          }
        },
      },
      components: {
        Unzip,
        Upload,
        Dezip,
        Rename,
        Copy,
        AddNew,

        FolderOpened,
        Document,
        More,
      },
    });
    return vue;
  }
}

export default Component;
