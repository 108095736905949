import BaseViews from "@/fast/base/BaseView";
import { defineComponent } from "vue";
import CommonSelect from "@/app/components/common/select/Select.vue";
import EventBus, { RenameData } from "@/fast/plugins/mitt/EventBus";
import IoController from "@/app/api/controller/IoController";
import { ElLoading, ElMessage } from "element-plus";

class Component extends BaseViews {
  constructor() {
    super();
  }

  public vue() {
    const vue = defineComponent({
      setup() {
        const ioC = new IoController();
        return {
          ioC,
        };
      },
      data() {
        return {
          show: false,
          title: "重命名",
          model: "move",
          type: "",
          path: "",
          name: "",
          newName: "",
          form: "",

          CSHref: "",
          CSType: "",
          CSMode: "dir",
        };
      },
      created() {
        EventBus.on("rename", this.rename);
      },
      mounted() {},
      onUnmounted() {
        EventBus.off("rename", this.rename);
      },

      methods: {
        rename(data: RenameData) {
          this.model = data.model;
          this.title = data.model == "rename" ? "重命名" : "移动";

          this.type = data.type;
          this.path = data.path;
          this.name = data.name;
          this.newName = "";

          let path = data.path == "/" ? "" : data.path;
          this.form = `${path}/${data.name}`;

          this.CSType = data.type;
          this.CSHref = data.path;

          this.show = true;
        },

        async renameOk() {
          if (this.newName.length > 0) {
            const loadingInstance = ElLoading.service({
              lock: true,
              text: "正在重命名",
            });
            let res: any = await this.ioC.rename({
              chain: true,
              method: "post",
              data: {
                from: this.form,
                to: `${this.path}/${this.newName}`,
                type: this.type,
              },
            });
            loadingInstance.close();
            if (res.result == true) {
              ElMessage.success(res.msg);
              EventBus.emit("ioSuccess");
              this.show = false;
            } else {
              ElMessage.error(res.msg);
            }
          }
        },
        async moveOk() {
          if (this.CSHref != "" && this.CSHref != this.path) {
            const loadingInstance = ElLoading.service({
              lock: true,
              text: "正在移动",
            });
            let res: any = await this.ioC.rename({
              chain: true,
              method: "post",
              data: {
                from: this.form,
                to: `${this.CSHref}/${this.name}`,
                type: this.type,
              },
            });
            loadingInstance.close();
            if (res.result == true) {
              ElMessage.success("移动成功");
              EventBus.emit("ioSuccess");
              this.show = false;
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("不能同路径输出");
          }
        },
      },
      components: {
        CommonSelect,
      },
    });
    return vue;
  }
}

export default Component;
