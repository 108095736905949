import BaseViews from "@/fast/base/BaseView";
import { defineComponent, ref } from "vue";
import { UploadFilled } from "@element-plus/icons-vue";
import { NUpload, NUploadDragger, NIcon, NText, NP } from "naive-ui";
import type {
  UploadInst,
  UploadCustomRequestOptions,
  UploadFileInfo,
} from "naive-ui";
import UploadTool from "@/app/tools/Upload";
import EventBus from "@/fast/plugins/mitt/EventBus";
class Component extends BaseViews {
  constructor() {
    super();
  }

  public vue() {
    const vue = defineComponent({
      name: "Upload",
      setup() {
        const submitBution = ref(false);
        const nUpload = ref<UploadInst | null>(null);
        const handleChange = (options: { fileList: UploadFileInfo[] }) => {
          let havePending = false;
          for (let index = 0; index < options.fileList.length; index++) {
            const element = options.fileList[index];
            if (element.status == "pending") {
              havePending = true;
              break;
            }
          }
          submitBution.value = havePending;
        };
        return {
          submitBution,
          nUpload,
          handleChange,
        };
      },
      data() {
        let show = false;
        let path = "/";
        return {
          show,
          path,
        };
      },
      created() {
        EventBus.on("upload", this.upload);
      },
      beforeUnmount() {
        EventBus.off("upload", this.upload);
      },
      methods: {
        upload(data: { path: string }) {
          this.show = true;
          this.path = data.path;
        },
        onSubmit() {
          if (this.nUpload) {
            this.nUpload.submit();
          }
        },
        customRequest({
          file,
          onFinish,
          onError,
          onProgress,
        }: UploadCustomRequestOptions) {
          UploadTool.uploadfile(
            file.file as File,
            {
              path: this.path,
            },
            {
              onFinish,
              onError,
              onProgress,
            }
          );
        },
      },
      components: {
        UploadFilled,
        NUpload,
        NUploadDragger,
        NIcon,
        NText,
        NP,
      },
    });
    return vue;
  }
}

export default Component;
