import BaseController from "@/fast/api/BaseController";
import CommonBll from "@/app/api/bll/CommonBll";
import {
  GetTokenReqCa,
  GetTokenReqCb,
  UploadReqCa,
  UploadReqCb,
  DoneReqCa,
  DoneReqCb,
} from "@/app/api/controller/UploadInterface";
import { axiosReqConfig } from "@/fast/api/BaseControllerInterface";

export default class UploadController extends BaseController {
  /**
   * 逻辑处理层
   */
  bll: CommonBll;

  constructor() {
    super();
    this.bll = new CommonBll();
  }

  async getToken(config: GetTokenReqCa | GetTokenReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(
      config,
      "/upload/getToken"
    );
    reqConfig.headers = this.bll.isLoginHeaders();
    return this.request(reqConfig);
  }

  async upload(config: UploadReqCa | UploadReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(config, "/upload");
    reqConfig.data = config.data;
    reqConfig.timeout = 3600000;
    reqConfig.headers = this.bll.uploadHeaders();

    return this.request(reqConfig);
  }

  async done(config: DoneReqCa | DoneReqCb) {
    let reqConfig: axiosReqConfig = this.bll.reqConfig(config, "/upload/done");
    reqConfig.timeout = 3600000;
    reqConfig.headers = this.bll.isLoginHeaders();
    return this.request(reqConfig);
  }
}
