import BaseViews from "@/fast/base/BaseView";
import { defineComponent } from "vue";
import CommonSelect from "@/app/components/common/select/Select.vue";
import EventBus, { CopyData } from "@/fast/plugins/mitt/EventBus";
import IoController from "@/app/api/controller/IoController";
import { ElLoading, ElMessage } from "element-plus";

class Component extends BaseViews {
  constructor() {
    super();
  }

  public vue() {
    const vue = defineComponent({
      setup() {
        const ioC = new IoController();
        return {
          ioC,
        };
      },
      data() {
        return {
          show: false,
          title: "复制",
          type: "",
          path: "",
          name: "",
          form: "",

          CSHref: "",
          CSType: "",
          CSModel: "dir",
        };
      },
      created() {
        EventBus.on("copy", this.copy);
      },
      mounted() {},
      onUnmounted() {
        EventBus.off("copy", this.copy);
      },
      methods: {
        copy(data: CopyData) {
          this.type = data.type;
          this.path = data.path;
          this.name = data.name;
          let path = data.path == "/" ? "" : data.path;
          this.form = `${path}/${data.name}`;
          this.CSHref = data.path;
          this.CSType = data.type;
          this.show = true;
        },
        async copyOk() {
          if (this.CSType == "dir") {
            if (this.CSHref != "" && this.CSHref != this.path) {
              const loadingInstance = ElLoading.service({
                lock: true,
                text: "正在复制",
              });
              let res: any = await this.ioC.copy({
                chain: true,
                method: "post",
                data: {
                  from: this.form,
                  to: `${this.CSHref}/${this.name}`,
                  type: this.type,
                },
              });
              loadingInstance.close();
              if (res.result == true) {
                ElMessage.success("复制成功");
                EventBus.emit("ioSuccess");
                this.show = false;
              } else {
                ElMessage.error(res.msg);
              }
            } else {
              ElMessage.error("不能同路径输出");
            }
          } else {
            ElMessage.error("类型有误");
          }
        },
      },
      components: {
        CommonSelect,
      },
    });
    return vue;
  }
}

export default Component;
