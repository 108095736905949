import BaseViews from "@/fast/base/BaseView";
import { defineComponent, ref } from "vue";
import IoController from "@/app/api/controller/IoController";
import { ElMessage } from "element-plus";
import Io, { ShowData } from "@/app/tools/Io";

class Component extends BaseViews {
  constructor() {
    super();
  }

  public vue() {
    const vue = defineComponent({
      setup() {
        const ioC = new IoController();
        let cities = ref<ShowData[]>([]);
        let selectValue = ref("");
        return {
          ioC,
          cities,
          selectValue,
        };
      },
      data() {
        return {};
      },
      props: {
        value: {
          type: String,
          deaful: "",
        },
        model: {
          type: String,
          deaful: "",
        },
        type: {
          type: String,
          deaful: "",
        },
      },
      watch: {
        value(val: string, oldval: string) {
          if (val != "") {
            this.getData();
          }
        },
      },
      created() {},
      mounted() {
        if (this.value != "") {
          this.getData();
        }
      },
      methods: {
        async getData() {
          let data: any = await this.ioC.show({
            data: {
              path: String(this.value),
            },
            chain: true,
            method: "post",
          });
          if (data.result == true) {
            this.cities = Io.dwDir(data.data.dir, String(this.value)).concat(
              Io.dwFile(data.data.file, String(this.value))
            );
          } else {
            ElMessage.error(data.msg);
          }
        },
        clickDir(val: ShowData) {
          this.$emit("update:value", val.href);
          this.$emit("update:type", val.type);
        },
      },
      components: {},
    });
    return vue;
  }
}

export default Component;
