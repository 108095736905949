import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bda63be0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"float":"left"} }
const _hoisted_2 = { style: {"float":"right","color":"var(--el-text-color-secondary)","font-size":"13px"} }
const _hoisted_3 = { style: {"float":"left"} }
const _hoisted_4 = { style: {"float":"right","color":"var(--el-text-color-secondary)","font-size":"13px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.value), 1)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_el_select, {
        modelValue: _ctx.selectValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectValue) = $event)),
        placeholder: "value"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cities, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.href
            }, [
              (_ctx.model != `file` && item.type == `dir`)
                ? (_openBlock(), _createBlock(_component_el_option, {
                    key: 0,
                    value: item.name,
                    onClick: ($event: any) => (_ctx.clickDir(item))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_1, _toDisplayString(item.name), 1),
                      _createElementVNode("span", _hoisted_2, _toDisplayString(item.type), 1)
                    ]),
                    _: 2
                  }, 1032, ["value", "onClick"]))
                : (_ctx.model != `dir` && item.type == `file`)
                  ? (_openBlock(), _createBlock(_component_el_option, {
                      key: 1,
                      value: item.name
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1),
                        _createElementVNode("span", _hoisted_4, _toDisplayString(item.type), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}